<template>
  <div class="wrapper">
    <el-carousel class="slideBox" height="100%" :interval="5000" arrow="never">
      <!-- <el-carousel-item> -->
      <div class="slideBox1" style="background-image: url('/images/order/order_01.jpg')">
        <div class="content animate__animated animate__fadeIn">
          <div class="title1">沧穹科技<span>产品定制</span></div>
          <div class="title2">
            品质硬件基础实施至行业应用解决方案<br />
            完善的产品体系，助力企业高效智能化管理水平
          </div>
        </div>
      </div>
      <!-- </el-carousel-item> -->
    </el-carousel>
    <div class="wrapper-content">
      <div class="row_content">
        <div class="content" v-loading="productsLoading">
          <el-carousel class="slidePro" height="100%" :interval="5000" :arrow="arrowPro">
            <el-carousel-item v-for="(group, index) in productsGroup" :key="'group_' + index">
              <div class="content_col">
                <template v-for="product in group.pros">
                  <div class="col_3" :key="product.id">
                    <div class="content_pro">
                      <div class="pro_content" @click="proDetail(product.id)">
                        <div class="pro_img"><img :src="product.imageUrl"/></div>
                        <div class="pro_title">{{ product.name }}</div>
                        <div class="pro_info" v-if="product.info1 && product.info1.length >= 90" v-html="product.info1.substring(0,90) + ' ...'"></div>
                        <div class="pro_info" v-if="product.info1 && product.info1.length < 90" v-html="product.info1"></div>
                      </div>
                      <div class="pro_button">
                        <el-button type="warning" icon="el-icon-shopping-cart-full" size="small" @click="addProduct(product.id)" :ref="'addBtn_' + product.id" :disabled="disabledAdddBtn(product.id)"> {{ displayAdddBtn(product.id) }} </el-button>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="row_content">
        <div class="content">
          <div class="m-mentitle">
            <span class="m-mentitle-line"></span>
            <span class="m-mentitle-text">我的购物车</span>
          </div>
          <div class="m-mycart">
            <div class="m-mycart-empty" v-if="selectedList.length < 1">
              <div class="m-mycart-emptyin">
                <img src="/images/order/cart-empty.png" />
                <span>您的购物车是空的，点击上面加购吧！</span>
              </div>
            </div>
            <div class="m-mycart-table" v-if="selectedList.length > 0">
              <table class="resultTable" cellspacing="0" cellpadding="0">
                <tr height="40px">
                  <th width="50%">产品名称</th>
                  <th width="300px">数量</th>
                  <th>操作</th>
                </tr>

                <tr class="tableRecord" v-for="(product, index) in selectedList" :key="index">
                  <td>
                    <div class="tableProName">
                      <div class="left"><img :src="product.imageUrl" /></div>
                      <div class="right" @click="proDetail(product.id)">
                        <div class="t1">{{ product.name }}</div>
                        <div class="t2">{{ product.info2}}</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="numOpt">
                      <img src="/images/order/sub.png" @click="addNumSub(product.id)" />
                      <input type="text" maxlength="4" min="1" @input="addNumChange(product.id)" :key="product.id" v-model="product.addNum" />
                      <img src="/images/order/add.png" @click="addNumAdd(product.id)" />
                    </div>
                  </td>
                  <td>
                    <el-link type="warning" @click="removeProduct(product.id)" icon="el-icon-delete"> 删除 </el-link>
                  </td>
                </tr>
              </table>
              <div class="mycart-submit">
                <div class="recordTotal">
                  共计<span>{{ addNumTotal() }}</span
                  >件商品
                </div>
                <el-button type="danger" size="medium" @click="submit">提交订单</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-dialog :visible.sync="contactVisible" :close-on-click-modal="false" custom-class="contactDialog" :model="contact">
        <div slot="title" class="dialog-title">
          <i class="el-icon-edit-outline"> 联系方式 </i>
        </div>
        <el-form ref="contactForm" label-width="20px" :rules="rules" :model="contact">
          <el-form-item label=" " prop="contactName">
            <el-input v-model="contact.contactName" type="input" maxlength="50">
              <template slot="prepend">您的姓名</template>
            </el-input>
          </el-form-item>
          <el-form-item label=" " prop="company">
            <el-input v-model="contact.company" type="input" maxlength="100">
              <template slot="prepend">公司名称</template>
            </el-input>
          </el-form-item>
          <el-form-item label=" " prop="address">
            <el-input v-model="contact.address" type="input" maxlength="100">
              <template slot="prepend">联系地址</template>
            </el-input>
          </el-form-item>
          <el-form-item label=" " prop="phone">
            <el-input v-model="contact.phone" type="input" maxlength="11">
              <template slot="prepend">手机号<i class="el-icon-phone-outline"></i></template>
            </el-input>
          </el-form-item>
          <el-form-item label=" " prop="vcode">
            <el-input v-model="contact.vcode" type="input" maxlength="4">
              <template slot="prepend">验证码<i class="el-icon-message"></i></template>
              <el-button slot="append" class="vcodeBtn" type="success" plain :disabled="vcodeClick > 0" @click="getVcode">
                <template v-if="vcodeClick > 0">( {{ vcodeClick }}s )重新获取</template>
                <template v-if="vcodeClick == 0">获取验证码</template>
              </el-button>
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button size="medium" type="success" @click="submitConfirm"> 确认订购 </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script scoped>
import http from "@/api/front";
import { validNum } from "@/utils/validate";
import { scrollTo } from "@/utils/scroll-to";
import { MsgSuccess } from "@/utils/message";
export default {
  data() {
    return {
      arrowPro: "never",
      groupNum: 3,
      productsGroup: [],
      productsList: [],
      selectedList: [],
      contactVisible: false,
      productsLoading: true,
      contact: {
        contactName: "",
        company: "",
        address: "",
        phone: null,
        vcode: null,
      },
      vcodeClick: 0,
      vcodeClickInterval: null,
      query: {
        page: 1,
        pageSize: 100,
      },
      rules: {
        contactName: [
          { required: true, message: "姓名不能为空", trigger: "blur" },
          { max: 50, message: "最大长度50字符", trigger: "blur" },
        ],
        company: [
          { required: true, message: "公司不能为空", trigger: "blur" },
          { max: 100, message: "最大长度100字符", trigger: "blur" },
        ],
        address: [
          { required: true, message: "联系地址不能为空", trigger: "blur" },
          { max: 100, message: "最大长度100字符", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          { validator: this.$rules.rulePhone, trigger: "blur" },
        ],
        vcode: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
          { min: 4, max: 4, message: "验证码不合法", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.queryProInfoPage();
  },
  beforeDestroy() {
    this.vcodeClickTimerStop();
  },
  methods: {
    queryProInfoPage() {
      this.productsLoading = true;
      http.queryProInfoPage(this.query, (res) => {
        if (res.success) {
          this.productsList = res.data.records;
          this.productsGroup = [];
          let len = this.productsList.length;
          let lineNum = len % this.groupNum === 0 ? len / this.groupNum : Math.floor(len / this.groupNum + 1);
          for (let i = 0; i < lineNum; i++) {
            let data = {};
            data.pros = this.productsList.slice(i * this.groupNum, i * this.groupNum + this.groupNum);
            this.productsGroup.push(data);
          }
          if (this.productsGroup.length > 1) {
            this.arrowPro = "always";
          }
        }
        this.productsLoading = false;
      });
    },
    proDetail(id) {
      let routeUrl = this.$router.resolve({
        path: "/products/info",
        query: { id: id },
      });
      window.open(routeUrl.href, "_blank");
    },
    addProduct(id) {
      for (let pro of this.selectedList) {
        if (pro.id === id) {
          return;
        }
      }

      for (let pro of this.productsList) {
        if (pro.id === id) {
          let selected = JSON.parse(JSON.stringify(pro));
          selected.addNum = 1;
          this.selectedList.push(selected);
        }
      }
      if (this.selectedList.length <= 1) {
        scrollTo(370, 200);
      }
    },
    displayAdddBtn(id) {
      for (let pro of this.selectedList) {
        if (pro.id === id) {
          return "已选择";
        }
      }
      return "加入购入车";
    },
    disabledAdddBtn(id) {
      for (let pro of this.selectedList) {
        if (pro.id === id) {
          return true;
        }
      }
      return false;
    },
    removeProduct(id) {
      for (let index in this.selectedList) {
        let pro = this.selectedList[index];
        if (pro.id === id) {
          this.selectedList.splice(index, 1);
          break;
        }
      }
    },
    addNumSub(id) {
      for (let pro of this.selectedList) {
        if (pro.id === id) {
          if (validNum(pro.addNum)) {
            pro.addNum = parseInt(pro.addNum) - 1;
          }
          pro.addNum = pro.addNum > 0 ? pro.addNum : 1;
        }
      }
    },
    addNumAdd(id) {
      for (let pro of this.selectedList) {
        if (pro.id === id) {
          if (validNum(pro.addNum)) {
            pro.addNum = parseInt(pro.addNum) + 1;
          }
          pro.addNum = pro.addNum > 0 ? (pro.addNum > 9999 ? 9999 : pro.addNum) : 1;
        }
      }
    },
    addNumChange(id) {
      for (let pro of this.selectedList) {
        if (pro.id === id) {
          pro.addNum = pro.addNum.replace(/[^\d]/g, "");
          if (pro.addNum == 0) {
            pro.addNum = 1;
          }
          return;
        }
      }
      return 1;
    },
    addNumTotal() {
      let total = 0;
      for (let pro of this.selectedList) {
        total += parseInt(pro.addNum);
      }
      return total;
    },
    submit() {
      if (this.selectedList.length < 1) {
        return;
      }
      this.contact.vcode = null;
      if (this.$refs["contactForm"]) {
        this.$refs["contactForm"].clearValidate();
      }

      this.contactVisible = true;
    },
    submitConfirm() {
      this.$refs["contactForm"].validate((valid) => {
        if (valid) {
          let submitInfo = JSON.parse(JSON.stringify(this.contact));
          submitInfo.productList = this.selectedList;
          http.submitOrderInfo(submitInfo, (res) => {
            if (res.success) {
              this.$router.push({
                path: "/order/success",
                query: {
                  phone: this.contact.phone,
                },
              });
              this.contactVisible = false;
            }
          });
        }
      });
    },
    getVcode() {
      this.$refs["contactForm"].validateField("phone", (valid) => {
        if (!valid) {
          let query = {};
          query.phone = this.contact.phone;
          query.smsNo = "S02";
          this.vcodeClickTimerStart();
          http.smsSend(query, (res) => {
            if (res.success) {
              MsgSuccess("验证码已发送");
            }
          });
        }
      });
    },
    vcodeClickTimer() {
      this.vcodeClick--;
      if (this.vcodeClick < 1) {
        this.vcodeClickTimerStop();
      }
    },
    vcodeClickTimerStart() {
      this.vcodeClickTimerStop();
      this.vcodeClick = 60;
      this.vcodeClickInterval = setInterval(this.vcodeClickTimer, 1000);
    },
    vcodeClickTimerStop() {
      if (this.vcodeClickInterval) {
        clearInterval(this.vcodeClickInterval);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "order.scss";
</style>
